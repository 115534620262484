.users-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: block;
  overflow: auto;
}

.users-container .role-dropdown {
  right: 0;
}

.users-container .error-card {
  color: #f00;
  display: none;
}

.users-container .show {
  color: red;
  font-size: 12px;
  display: block;
}

.users-container .error-text {
  width: 50%;
  padding-left: 6px;
  padding-right: 0;
}

.users-container .ui form .fields {
  display: flex;
  align-items: flex-end;
}

.users-container .error-text:nth-child(2) {
  width: 50%;
  margin-right: 0 !important;
}

.users-container .content-block {
  display: flex;
  justify-content: space-between;
}

.users-container .btn-var {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.users-container .user-body {
  flex: 1;
  display: flex;
}

.users-container .user-body .field {
  width: 40%;
  margin-right: 2%;
}

.users-container .page-content {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.users-container .pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.users-container .pagination span {
  align-self: center;
  margin-right: 10px;
}

.users-container .label-text {
  padding: 8px 7px 8px 11px !important;
}

.users-container .records-count {
  color: #3386cb;
  font-weight: 600;
  margin-left: 10px;
}

.users-container .sort-icon {
  background: transparent !important;
}

.users-container .sort-icon i {
  color: #3386cb;
}

.users-container .action-button-block {
  margin-top: 1%;
  display: block;
}

.users-container .action-button-block :first-child {
  display: inline-block;
}

.users-container .action-button-child-block {
  margin-left: 1%;
  display: inline-block;
  color: #348ecd;
}

.users-container .action-check {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.users-container .save-btn button {
  background-color: #4b92de !important;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .users-container .content-block {
    flex-direction: column;
  }
  .users-container .user-body .field {
    width: 60%;
    margin-right: 2%;
  }
  .users-container .page-content {
    flex-direction: column;
  }

  .users-container .btn-var {
    margin-top: 10px;
    justify-content: flex-start;
  }

  .users-container .action-check {
    justify-content: flex-end;
  }

  .users-container .pagination {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .users-container .user-pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .users-container .save-btn button {
    margin-top: 10px !important;
  }
}

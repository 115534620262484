h1 {
    font-size: 1.4em;
}

em {
    font-style: normal;
}

a {
    text-decoration: none;
    color: inherit;
}

/* Layout */
.s-layout {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

.s-layout__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

/* Sidebar */
.s-sidebar__trigger {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4em;
    background: #192b3c;
}

.s-sidebar__trigger > i {
    display: inline-block;
    margin: 1.5em 0 0 1.5em;
    color: #f07ab0;
}

.s-sidebar__nav {
    position: fixed;
    top: 0;
    left: -15em;
    overflow: hidden;
    transition: all .3s ease-in;
    width: 15em;
    height: 100%;
    background: #164373;
    color: rgba(255, 255, 255, 0.7);
    z-index: 1;
}

.s-sidebar__nav:hover,
.s-sidebar__nav:focus,
.s-sidebar__trigger:focus + .s-sidebar__nav,
.s-sidebar__trigger:hover + .s-sidebar__nav {
    left: 0;
}

.s-sidebar__nav ul {
    position: absolute;
    top: 4em;
    left: 0;
    margin: 0;
    padding: 0;
    width: 15em;
}

.s-sidebar__nav-link {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 3em;
    color: #fff;
}

.s-sidebar__nav-link em {
    position: absolute;
    top: 50%;
    left: 23px;
    transform: translateY(-50%);
    display: inline-flex;
    width: 13em;
}

.s-sidebar__nav:focus,
.s-sidebar__nav-link em i {
    margin-right: 20px;
}

.s-sidebar__nav-link:hover {
    background: #fff;
    color: #243e56;
}

.s-sidebar__nav-link > i {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 4em;
    height: 4em;
}

.s-sidebar__nav-link > i::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.s-sidebar__nav-link .log-out-icon {
    font-size: 25px;
}

.s-sidebar-span {
    align-items: center;
    font-size: 25px;
    display: flow-root;
}

.sidebar-logo-img {
    width: 120px;
    height: auto;
    margin: 20px auto 0;
    display: table;
}

.s-sidebar-logo {
    padding-top: 15px;
}

.admin-main-body {
    width: calc(100% - 210px);

}

.s-layout__sidebar {
    width: 210px;
}

.admin-main-body div
h2 {
    text-align: left;
}

.s-sidebar__nav-link-logout {
    position: absolute;
    bottom: 0;
}

.s-sidebar__nav-link-logout em {
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    position: relative;
    color: #fff;
    font-size: 17px;
}

.s-sidebar__nav-link-logout em i {
    margin-right: 20px;
}

.logo-btn {

}

.NavBar-header {
    width: 100%;
    background: #ddd;
    box-shadow: 0px 0px 5px #0000008a;
}

body .ui.thin.left.sidebar, body .ui.thin.right.sidebar {
    width: 200px;
}

.this-is-sidebar {
    overflow: hidden;
}

.this-is-sidebar li {
    list-style: none;
    text-align: left;
}

.app-logo {
    padding: 20px 20px 15px;
    margin-bottom: 70px;
}

body .ui.visible.thin.left.sidebar ~ .pusher {
    transform: translate3d(200px, 0, 0);
    width: calc(100% - 200px);
    transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

.main_box .ui.segment {
    padding: 0;
}

.main_box .header-stick-navbar {
    background: #f7f7f7;
    box-shadow: 3px 1px 2px #00000047;
    border-bottom: 1px solid #e4e9f0;
}

.header-stick-navbar {
    display: flex;
    justify-content: space-between;
}

.header-stick-navbar li {
    list-style: none;
    float: left;
}

.header-stick-navbar li a {
    color: #192b3c;
}

.stick-header-right {
    display: flex;
    padding-right: 25px;
    align-self: center;
}

.content_main {
    height: 100vh !important;
    overflow: hidden;

}

.main-body {
    height: calc(100vh - 52px);
}

.stick-header-left {
    margin: 5px;
}

.header-menu-items {
    width: 90%;
    z-index: 99;
}

.header-menu-items li {
    width: auto;
    margin: 5px;
    position: relative;
}

.header-menu-items li a {
    height: 3em;
    padding: 10px;
    border-radius: 5px;
    width: auto;
}

.header-menu-items li a:hover {
    color: #4b92de;
}

.sub-menu {
    margin: 0;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-out;
    padding: 0;
    padding-left: 10px;
    position: absolute;
    z-index: 1;
    background: #f7f7f7;

}

.sub-menu li {
    float: none;
}

.sub-menu li a {
    width: 130px;
}

.header-menu-items li:hover .sub-menu,
.nav-sub-menu:hover .sub-menu {
    display: block;
    max-height: 500px;
}

.nav-sub-menu .sub-menu {
    background: inherit;
    position: relative;
    width: 100%;
}

.nav-sub-menu .sub-menu li a {
    width: 100%;
}

.displayNone {
    display: none;
}

/* Mobile First */
@media only screen and (min-width: 320px) and (max-width: 778px) {
    /*.s-sidebar__nav-link em i {*/
    /*margin-right: 10px;*/
    /*}*/
    /*.s-sidebar__nav ul li:last-child{*/
    /*width:0;*/
    /*}*/
    /*.s-sidebar__nav ul li a em:last-child{*/
    /*width: 0;*/
    /*}*/
    /*.s-sidebar__nav ul li a em span:last-child{*/
    /*width: 0;*/
    /*}*/
    .s-sidebar__nav-link {
        width: 0;
    }

    .sidebar-logo-img {
        width: 100px;
        height: 40px;
        margin-top: 0;
    }

    .s-sidebar-span {
        font-size: 20px;
    }

    .s-sidebar-logo {
        padding-top: 70px;
    }

    .s-sidebar__nav ul {
        position: absolute;
        top: 9em;
        left: 0;
        margin: 0;
        padding: 0;
        width: 15em;
    }

    .s-sidebar__trigger {
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        background: #192b3c;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .s-layout__sidebar {
        width: 60px;
    }

    .admin-main-body {
        width: calc(100% - 60px);

    }

    .header-menu-items {
        display: none;
    }


}

@media only screen and (min-width: 779px) and (max-width: 1024px) {
    /*.s-sidebar__nav ul li:last-child{*/
    /*width: 0;*/
    /*}*/
    .s-sidebar__nav-link {
        width: 0;
    }

    .sidebar-logo-img {
        width: 100%;
        height: 40px;
    }

    .s-sidebar-span {
        font-size: 20px;
    }

    .s-sidebar-logo {
        padding-top: 70px;
    }

    .s-sidebar__nav ul {
        position: absolute;
        top: 9em;
        left: 0;
        margin: 0;
        padding: 0;
        width: 15em;
    }

    .s-sidebar__trigger {
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        width: 16%;
        height: 4em;
        background: #192b3c;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .s-layout__sidebar {
        width: 60px;
    }

    .admin-main-body {
        width: calc(100% - 60px);
    }
}

@media only screen and (min-width: 42em) {
    .s-layout__content {
        margin-left: 4em;
    }

    /* Sidebar */
    .s-sidebar__trigger {
        width: 4em;
        height: 4em;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .s-sidebar__trigger i {
        font-size: 20px;
        color: white;
        margin: 0;
    }

    .s-sidebar__nav {
        width: 4em;
        left: 0;
    }

    .s-sidebar__nav:hover,
    .s-sidebar__nav:focus,
    .s-sidebar__trigger:hover + .s-sidebar__nav,
    .s-sidebar__trigger:focus + .s-sidebar__nav {
        width: 15em;
    }
}

@media (min-width: 68em) {
    .s-layout__content {
        margin-left: 15em;
    }

    /* Sidebar */
    .s-sidebar__trigger {
        display: none
    }

    .s-sidebar__nav {
        width: 15em;
    }

    .s-sidebar__nav ul {
        top: 5.3em;
    }
}

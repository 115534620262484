.message-background {
    color: #fff !important;
    width: 30%;
}

.success-class {
    background-color: seagreen !important;
}

.error-class {
    background-color: #d32f2f !important;
}

.info-class {
    background-color: #2196f3 !important;
}

.warning-class {
    background-color: #ffa000 !important;
}

.ui.message.custom-margin-auto {
    margin: 0 auto;
    bottom: 1px;
    z-index: 1;
    right: 5%;
    left: 0;
}

.full-width {
    width: 100%;
}

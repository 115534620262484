.login-form .ui.stacked.segment:after {
    display: none;
}

.login-form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

.main-wrap {
    max-width: 450px;
}

.custom-accordion.ui.styled.accordion, .custom-accordion.ui.styled.accordion .accordion {
    box-shadow: none;
}

.move-file-wrap.custom-accordion.ui.styled.accordion {
    max-height: 250px;
    overflow: auto;
}

.custom-accordion.ui.styled.accordion .accordion .title, .custom-accordion.ui.styled.accordion .title {
    border: none;
    border-radius: 0 3rem 3rem 0;
}

.custom-accordion.ui.styled.accordion .title:hover {
    background-color: rgba(0, 0, 0, .04);
}

.custom-accordion.ui.styled.accordion .selected-accordion .title {
    color: #2185d0;
}

.custom-accordion.ui.styled.accordion .content {
    padding-top: 0;
    padding-bottom: 0;
}

.custom-accordion.ui.accordion .accordion {
    margin: 0;
}

.custom-modal {
    min-height: 150px;
}

.top-performer-container {
  width: 100%;
  height: 100%;
  padding: 30px;
  display: block;
  overflow: auto;
}

.top-performer-container tr .file-icon {
    padding-right: 17px;
}

.top-performer-container tr .folder-icon {
    padding-right: 10px;
}

.top-performer-container .role-dropdown {
  right: 0;
}

.top-performer-container .page-content {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.top-performer-container .pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.top-performer-container .pagination span {
  align-self: center;
  margin-right: 10px;
}

.top-performer-container .records-count {
  color: #3386cb;
  font-weight: 600;
  margin-left: 10px;
}

.top-performer-container .sort-icon {
  background: transparent !important;
}

.top-performer-container .sort-icon i {
  color: #3386cb;
}

@media screen and (max-width: 768px) {
  .top-performer-container .page-content {
    flex-direction: column;
  }

  .top-performer-container .pagination {
    justify-content: flex-start;
    margin-top: 10px;
  }

  .top-performer-container .report-pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.main-col .custom-accordion.ui.styled.accordion {
    height: calc(100% - 72px);
    overflow: auto;
}

.custom-accordion.ui.styled.accordion, .custom-accordion.ui.styled.accordion .accordion {
    box-shadow: none;
}

.custom-accordion.ui.styled.accordion .accordion .title, .custom-accordion.ui.styled.accordion .title {
    border: none;
    border-radius: 0 3rem 3rem 0;
}

.custom-accordion.ui.styled.accordion .title:hover {
    background-color: rgba(0, 0, 0, .04);
}

.custom-accordion.ui.styled.accordion .title.selected {
    color: #2185d0;
}


.custom-accordion.ui.styled.accordion .content {
    padding-top: 0;
    padding-bottom: 0;
}

.custom-accordion.ui.accordion .accordion {
    margin: 0;
}

.btn-dropdown.ui.dropdown {
    border-radius: 3rem;
    margin: 14px;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-dropdown.ui.dropdown:hover {
    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);
    background-color: #f8f9fa;
    outline: none;
}

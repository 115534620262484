.blur-modal.ui.modal > .header {
    display: flex;
    justify-content: space-between;
}

.blur-modal.ui.modal > .header .title {
    font-size: 24px;
    margin-bottom: 0;
}

.blur-modal.ui.modal > .header .sub-title {
    font-size: 14px;
}

.header-wrapper {
    background-color: #f0f8ff;
}

.modal-description {
    flex: 0 0 auto;
    width: 100%;
}

.home-container.ui.grid {
    width: 100%;
    margin: 0;
    height: 100%;
}

.home-container.ui.grid .column.main-col {
    height: 100%;
}
@media screen and (max-width: 767px){
    .home-container.ui.grid .column.main-col {
        height: calc(100% - 68px);
    }
    .search-file .input {
        width: 100% !important;
    }
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.d-flex {
    display: flex;
}

.w-100 {
    width: 100%;
}

.justify-content-center {
    justify-content: center;
}

.text-light {
    color: rgba(0, 0, 0, .4);
}

.text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.text-left {
    text-align: left;
}

.ui.basic.folder-btn.active {
    background-color: #e8f0fe !important;
    color: #1967d2 !important;
}

.ui.basic.folder-btn {
    margin-bottom: 30px;
}

.folder-btn i {
    color: #5f6368;
    margin-right: 12px;
}

.ui.basic.folder-btn.public {
    background-color: #e0e1e2 !important;
}

.ui.basic.folder-btn.private {
    background-color: #cecee7 !important;
}

.ui.basic.file-btn.public {
    background-color: #e0e1e2 !important;
}

.ui.basic.file-btn {
    margin-bottom: 30px;
    padding: 0;
}

.file-btn i {
    color: #5f6368;
    margin-right: 12px;
}

.header {
    color: #5f6368;
    margin-left: 10px;
    padding: 0;
    font-size: 18px;
}

.folder-action .dropdown {
    position: absolute;
}

.custom-breadcrumb.ui.breadcrumb .section {
    border-radius: 8px;
    margin: 2px 0;
    padding: 4px 8px 4px;
    color: #3c4043;
}

.custom-breadcrumb.ui.breadcrumb .section:hover {
    background-color: #f1f3f4;
}

.file-img {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.file-name {
    padding: 20px;
}

.file-img i {
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.main-details {
    overflow: auto;
    height: calc(100% - 120px);
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.download-as a {
    color: #0073bb;
    text-decoration: none;
}

.download-as a:hover {
    text-decoration: underline;
}

.search-file .input {
    width: 500px;
}

.weekly-stat {
    display: flex;
}

.weekly-stat div {
    margin-left: 16px;
}

.label-left-floating {
    position: absolute;
    z-index: 100;
    top: -1em;
    left: 6%;
    margin: 0 0 0 -1.5em !important;
    cursor: pointer;
}
